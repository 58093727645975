import { FC, ReactElement } from 'react';
import { useDraggable } from '@dnd-kit/core';
import classNames from 'classnames';
import { FORMA_FOLDER_ID_PACKS, FORMA_FOLDER_ID_STATIC, FORMA_FOLDER_ID_STRICT } from 'data/constants';

import styles from './drag-item.module.css';

interface DragItemProps {
  id: string,
  type: 'template'|'folder',
  name: string,
  folderId: string|null,
  isUpdated?: boolean,
  isMenuOpen?: boolean,
  isModalOpen?: boolean,
  children: ReactElement
}

const DragItem: FC<DragItemProps> = ({
  id, type, name, folderId, isUpdated, isMenuOpen, isModalOpen, children
}) => {
  const { attributes, listeners, setNodeRef: dragRef, transform, isDragging } = useDraggable({
    id,
    data: { id, type, name, folderId },
    disabled: isMenuOpen || isModalOpen || [FORMA_FOLDER_ID_STATIC, FORMA_FOLDER_ID_PACKS, FORMA_FOLDER_ID_STRICT].includes(id)
  });
  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : '',
    opacity: isDragging ? 0 : 1
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.border} />
      <div
        className={classNames(
          styles.children,
          isUpdated && styles.updated,
          isMenuOpen && styles.menuOpen
        )}
        style={style}
        {...listeners}
        {...attributes}
        ref={dragRef}
      >
        {children}
      </div>
    </div>
  );
};

export default DragItem;
