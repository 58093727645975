import { useEffect } from 'react';
import { NavigateFunction } from 'react-router-dom';

const useQueryErrorRedirect = (pageError: unknown, navigate: NavigateFunction) => {
  useEffect(() => {
    if (pageError && typeof pageError === 'object' && 'status' in pageError && 'name' in pageError) {
      if (pageError.status === 402) navigate('/subscribe');
      else if (pageError.status !== 'FETCH_ERROR' && pageError.name !== 'AbortError') navigate('/404');
    }
  }, [pageError, navigate]);
};


export default useQueryErrorRedirect;
