import { ComponentProps, useContext, useEffect, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Button, LoadingButton, ThemeContext, useClickOutside } from '@forma/forma-ui-kit';

import styles from './button-multi.module.css';

interface ButtonMultiProps extends ComponentProps<typeof LoadingButton> {
  rootClassName?: string,
  buttons: ComponentProps<typeof LoadingButton>[]
}

const ButtonMulti = ({ rootClassName, buttons, isLoading, ...props }: ButtonMultiProps) => {
  const containerRef = useRef(null);
  const { viewport } = useContext(ThemeContext);
  const [ isOpen, setOpen ] = useState(false);

  useClickOutside(() => setOpen(false), containerRef, [viewport]);

  useEffect(() => {
    if (isLoading) setOpen(false);
  }, [isLoading]);

  return (
    <div className={classNames(styles.root, isOpen && styles.open, rootClassName)} ref={containerRef}>
      <div className={styles.main}>
        <LoadingButton
          {...buttons[0]}
          {...props}
          isLoading={isLoading}
          className={classNames(buttons[0].className, styles.button, isLoading && styles.loading)}
          iconClassName={classNames(buttons[0].iconClassName, styles.buttonIcon)}
          fullWidth
        />
        <Button
          viewStyle={props.viewStyle}
          className={styles.dropdownButton}
          disabled={props.disabled}
          iconClassName={styles.dropdownButtonIcon}
          icon={<ReactSVG src="/icons/dropdown.svg" wrapper="span" />}
          onClick={() => setOpen(!isOpen)}
        />
      </div>
      {isOpen && (
        <div className={styles.buttons}>
          {buttons.map((button, index) => index === 0 ? null : (
            <LoadingButton
              {...button}
              {...props}
              className={classNames(button.className, styles.button)}
              iconClassName={classNames(button.iconClassName, styles.buttonIcon)}
              fullWidth
              key={button.id ?? index}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ButtonMulti;
