import { IDocumentExternal, IDocumentRender, IDocumentStrict } from 'interfaces/documents.interface';
import { IRendersItem, IRendersList } from 'interfaces/templates.interface';
import { baseApi } from 'store/store';

export const documentsApi = baseApi
  .injectEndpoints({
    endpoints: builder => ({
      getDocuments: builder.query<{ count: number, items: (IDocumentRender|IDocumentExternal|IDocumentStrict)[] }, {
        from: number, to: number, name?: string, fromCreatedAt?: string, toCreatedAt?: string
      }>({
          query: (data) => ({
            url: '/documents',
            method: 'GET',
            params: data
          }),
          providesTags: ['Documents']
        }),
      getMyDocuments: builder.query<IRendersList, { from?: number, to?: number, name?: string, fromAt?: string, toAt?: string }>({
        query: data => ({
          url: `/renders/`,
          method: 'GET',
          params: data
        }),
        providesTags: ['Documents']
      }),
      getDocumentById: builder.query<IRendersItem, string>({
        query: id => ({
          url: `/renders/${id}`,
          method: 'GET',
        }),
        providesTags: (result, error, id) => [{ type: 'Documents', id }]
      }),
      removeDocument: builder.mutation<{ id: string }, string>({
        query: id => ({
          url: `/renders/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Documents']
      }),
      confirmSign: builder.mutation({
        query: data => {
          return {
            url: `/renders/${data}/confirm`,
            method: 'PUT',
            body: data
          };
        },
        invalidatesTags: ['Documents']
      }),
    })
  });

export const {
  useGetDocumentsQuery,
  useGetDocumentByIdQuery,
  useGetMyDocumentsQuery,
  useRemoveDocumentMutation,
  useConfirmSignMutation,
} = documentsApi;