import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, PaginationProps, PaginationWrapper } from '@forma/forma-ui-kit';
import DocumentsNoItems from '../DocumentsNoItems';
import DocumentsHeader from './DocumentsHeader';
import { DocumentsItemExternal, DocumentsItemRender, DocumentsItemSign, DocumentsItemStrict } from './DocumentsItem';

import { IDocumentExternal, IDocumentRender, IDocumentStrict } from 'interfaces/documents.interface';
import { IExternalFillData } from 'store/externalfill/externalfillApi';

import styles from './documents-list.module.css';

interface DocumentsListProps {
  items?: (IDocumentRender|IDocumentExternal|IDocumentStrict)[],
  filters: { [key: string]: string|number },
  pagination: PaginationProps,
  onChangeSearch: (value: string) => void,
  onChangeFilter: (params: { fromCreatedAt?: string, toCreatedAt?: string }) => void,
  openedId?: string,
  renderFill: {
    onSign: (id: string) => void,
    onRename: (id: string, name: string) => void,
    onRemove: (id: string) => void,
  },
  strictFill: {
    onRename: (id: string, name: string) => void,
    onRemove: (id: string) => void,
  },
  externalFill: {
    openedId?: string|null,
    sendingId?: string|null,
    onCopyLink: (key: string, type: 'filling'|'sign') => void,
    onEdit: (data: IExternalFillData) => void,
    onRename: (id: string, name: string) => void,
    onRemove: (id: string) => void,
    onSend: (id: string, data: { login: string }) => void,
    onDuplicate: (id: string) => void,
  }
}

const DocumentsList: FC<DocumentsListProps> = ({
  items, filters, pagination, onChangeSearch, onChangeFilter, renderFill, externalFill, strictFill
}) => {
  const { t } = useTranslation();

  const columns: { children: string|null, className: string }[] = [
    { children: '', className: styles.cellIcon },
    { children: t('name'), className: styles.cellName },
    { children: t('created'), className: styles.cellCreated },
    { children: t('signed'), className: styles.cellSigned },
    { children: '', className: styles.cellButtons }
  ];

  if (!pagination.isLoading
    && !items?.length
    && !Object.values(filters).find(val => !!val)
    && (!pagination.page || pagination.page === 1)
  ) return (
    <DocumentsNoItems />
  );

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <DocumentsHeader
          onChangeSearch={onChangeSearch}
          onChangeFilter={onChangeFilter}
        />
      </div>

      <div className={styles.content}>
        <PaginationWrapper {...pagination}>
          <Table columns={columns} fixed>
            {!!items?.length && items.map(item => {
              if (item.type === 'externalInfill') {
                if (item.signature) return (
                  <DocumentsItemSign
                    data={item}
                    {...externalFill}
                    key={item.id}
                  />
                );
                return (
                  <DocumentsItemExternal
                    data={item}
                    {...externalFill}
                    key={item.id}
                  />
                );
              }
              if (item.type === 'strictRender') {
                return (
                  <DocumentsItemStrict
                    data={item}
                    {...strictFill}
                    key={item.id}
                  />
                );
              }
              return (
                <DocumentsItemRender
                  data={item}
                  {...renderFill}
                  key={item.id}
                />
              );
            })}
          </Table>
        </PaginationWrapper>
      </div>
    </div>
  );
};

export default DocumentsList;
