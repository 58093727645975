import { FC, useState } from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { ContextMenu, RemoveModal } from '@forma/forma-ui-kit';
import StrictRenderModal from 'pages/MyDocuments/StrictRenderModal';
import RenameModal from 'components/RenameModal';
import DocumentsItem from './DocumentsItem';

import { IDocumentStrict } from 'interfaces/documents.interface';

import styles from './documents-item.module.css';

interface DocumentsItemStrictProps {
  data: IDocumentStrict,
  defaultOpen?: boolean,
  onRename: (id: string, name: string) => void,
  onRemove: (id: string) => void,
}

// TODO: объединить контролы в один компонент

const DocumentsItemStrict: FC<DocumentsItemStrictProps> = ({
  data, defaultOpen, onRename, onRemove
}) => {
  const { t } = useTranslation();
  const { id, name, createdAt } = data;
  const [ isShowEditModal, setShowEditModal ] = useState<boolean>(!!defaultOpen);
  const [ isShowRename, setShowRename ] = useState<boolean>(false);
  const [ isShowRemove, setShowRemove ] = useState<boolean>(false);

  return (
    <DocumentsItem
      id={id}
      name={name}
      icon="/icons/documents/simple.svg"
      createdAt={createdAt}
      contextMenu={<>
        <ContextMenu
          offsetY={8}
          control={open => (
            <button className={classNames(styles.optionsButton, open && styles.active)}>
              <ReactSVG src="/icons/more.svg" />
            </button>
          )}
          items={[
            {
              text: t('rename'),
              disabled: true,
              onClick: () => setShowRename(true)
            }, {
              text: t('delete'),
              onClick: () => setShowRemove(true)
            },
          ]}
        />
        <RenameModal
          open={isShowRename}
          onClose={() => setShowRename(false)}
          onSubmit={(name) => onRename(id, name)}
          name={name}
          title={t('rename_external_fill')}
          placeholder={t('enter_name')}
        />
        <RemoveModal
          open={isShowRemove}
          onClose={() => setShowRemove(false)}
          onRemove={() => onRemove(id)}
          itemName={name}
          title={t('deleting_document')}
        />
        <StrictRenderModal
          open={isShowEditModal}
          onClose={() => setShowEditModal(false)}
          id={data.id}
          name={data.name}
        />
      </>}
      onClick={() => setShowEditModal(true)}
    />
  );
};

export default DocumentsItemStrict;
