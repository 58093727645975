import { FC, MouseEvent, useRef } from 'react';
import { templatesIcons } from 'data/mock';
import classNames from 'classnames';

import { IStrictTemplateItem } from 'interfaces/strictTemplates.interface';

import styles from './strict-item.module.css';

interface StrictItemProps extends IStrictTemplateItem {
  actions: {
    onClick: (id: string) => void,
    onDoubleClick: (id: string) => void,
  },
}

const StrictItem: FC<StrictItemProps> = ({ id, name, actions }) => {
  const timer = useRef<NodeJS.Timer|null>(null);

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (timer.current) clearTimeout(timer.current);
    if (e.detail === 1) {
      e.preventDefault();
      timer.current = setTimeout(() => actions.onClick(id), 200);
    } else if (e.detail === 2) {
      actions.onDoubleClick(id);
    }
  };

  const icon = templatesIcons.grid.files.strict;

  return (
    <div className={classNames(styles.root, 'template-strict-item')}>
      <div
        className={styles.container}
        onClick={handleClick}
        // onContextMenu={e => { e.preventDefault(); setOptionsOpen(true); }}
      >
        <div className={styles.iconContainer}>
          <img
            className={styles.icon}
            src={icon}
            alt=""
          />
        </div>
        <div className={styles.nameContainer}>
          <div className={styles.name}>
            {name}
          </div>
        </div>
      </div>

      {/* <ContextMenu
        open={isOptionsOpen}
        items={options}
        control={open => <button className={classNames(styles.optionsButton, open && styles.active)}><ReactSVG src="/icons/more.svg" /></button>}
        offsetY={12}
        offsetX={14}
        onOpen={() => { setOptionsOpen(true); navigation.onToggleMenu && navigation.onToggleMenu(id); }}
        onClose={() => { setOptionsOpen(false); navigation.onToggleMenu && navigation.onToggleMenu(null); }}
      /> */}
    </div>
  );
};

export default StrictItem;
