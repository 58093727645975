import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@forma/forma-ui-kit';
import ButtonMulti from 'components/ButtonMulti';

import { TFormatDocument } from 'interfaces/templates.interface';

interface DownloadButtonProps {
  formats: TFormatDocument[],
  disabled?: boolean,
  className?: string,
  isLoading?: boolean,
  onClick: (format: TFormatDocument) => void
}

const DownloadButton: FC<DownloadButtonProps> = ({ formats, disabled, className, isLoading, onClick }) => {
  const { t } = useTranslation();

  if (!formats.length) return null;

  if (formats?.length > 1) return (
    <ButtonMulti
      viewStyle="secondary"
      disabled={disabled}
      isLoading={isLoading}
      rootClassName={className}
      reversed
      buttons={formats?.map(format => ({
        id: `save_filled_button_${format}`,
        type: 'submit',
        onClick: () => onClick(format),
        icon: <ReactSVG src={`/icons/file_types/${format}.svg`} wrapper="span" />,
        children: t(`download_${format}`)
      })) ?? []}
    />
  );

  const format = formats[0];

  return (
    <LoadingButton
      id={`save_filled_button_${format}`}
      type="submit"
      viewStyle="secondary"
      disabled={disabled}
      isLoading={isLoading}
      className={className}
      onClick={() => onClick(format)}
      icon={<ReactSVG src={`/icons/file_types/${format}.svg`} wrapper="span" />}
      reversed
    >
      {t(`download_${format}`)}
    </LoadingButton>
  );
};

export default DownloadButton;