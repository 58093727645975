import update from 'immutability-helper';
import { ITemplateSide } from '@forma/forma-ui-kit';
import { ISideEditData, ISidesListEditData, ISidesListSendingData, ITemplateVariation } from 'interfaces/templates.interface';

export const clearSidesForExternalFill = (sidesData: ISidesListEditData, sendingData: ISidesListSendingData) => (
  Object.values(sidesData).map(({ id, tableValues, articleValues, tattrErrors, tattrValues, isValid, ...rest }) => {
    const sideData = sidesData[id];
    return ({
      id: id,
      // tattrValues: tattrValues,
      tattrValues: Object.keys(sideData.tattrValues)
        .reduce((acc, tattrId) => (
          sendingData[id].tattrs.includes(tattrId) ? acc : { ...acc, [tattrId]: sideData.tattrValues[tattrId] }
        ), {}),
      tableValues: sideData.tableValues?.filter(table => !sendingData[id].tables?.includes(table.tableId))
        .map(table => ({ ...table, products: Object.values(table.products) })),
      articleValues: sideData.articleValues?.filter(article => !sendingData[id].articles?.includes(article.id)),
      ...rest
    });
  })
);

export const calculateSidesFillingCounts = (
  sidesToFilling: ITemplateSide[], sidesData?: ISidesListEditData, sendingData?: ISidesListSendingData | null, countSending?: boolean
) => (
  sidesToFilling.map(({ id, tattrFolders, carticles, tables }) => {
    const sideValues = sidesData && sidesData[id];
    const sendingTattrs = sendingData?.[id].tattrs;
    const sendingArticles = sendingData?.[id].articles;
    const sendingTables = sendingData?.[id].tables;

    let tattrsCount = 0;
    let filledCount = 0;

    tattrFolders.forEach(({ tattrs }) => {
      tattrs.forEach(({ id: tattrId }) => {
        tattrsCount += 1;

        if (sideValues && (
          (!!sideValues.tattrValues?.[tattrId] && !sideValues.tattrErrors?.[tattrId])
          || (countSending && sendingTattrs?.includes(tattrId))
        )) filledCount += 1;
      });
    });

    tables?.forEach(({ id }) => {
      tattrsCount += 1;
      const tableProducts = sideValues && sideValues.tableValues.find(table => id === table.tableId)?.products;
      if (
        (tableProducts && Object.keys(tableProducts).length)
        || (countSending && sendingTables?.includes(id))
      ) filledCount += 1;
    });

    carticles?.forEach(({ id }) => {
      tattrsCount += 1;
      if (sideValues && (
        sideValues.articleValues.find(article => id === article.id)?.value?.length
        || (countSending && sendingArticles?.includes(id))
      )) filledCount += 1;
    });

    return { count: filledCount, total: tattrsCount };
  })
);

export const checkSideValid = (side: ITemplateSide, sideData: ISideEditData) => {
  const { isValid, articleValues, tableValues } = sideData;
  if (side.tattrFolders.length && !isValid) return false;

  if (side.carticles?.length) {
    if (articleValues.length < side.carticles.length) return false;
    if (!side.carticles.every(article => !!articleValues.find(({ id }) => id === article.id)?.value.length)) return false;
  }

  if (side.tables?.length) {
    if (!side.tables.every(({ id }) => {
      const tableValue = tableValues.find(({ tableId }) => tableId === id);
      return tableValue && Object.keys(tableValue.products).length;
    })) return false;
  }

  return true;
};

const clearArticleValue = (carticles: ITemplateVariation[], articleValues: ISideEditData['articleValues'], vid: string) => {
  let next = [...articleValues];
  const carticle = carticles.find(article => article.id === vid);

  const articleIndex = next.findIndex(article => article.id === vid);
  if (articleIndex !== -1) next[articleIndex] = update(next[articleIndex], { value: { $set: [] } });

  carticle?.variants.forEach(({ id }) => {
    const childCarticle = carticles.find(({ parents }) => !!parents?.includes(id));
    if (childCarticle) next = clearArticleValue(carticles, next, childCarticle.id);
  });
  return next;
};

export const clearSideDataByCarticleId = (sideData: ISideEditData, carticles: ITemplateVariation[], vid: string): ISideEditData => {
  const articleValues = clearArticleValue(carticles, sideData.articleValues, vid);

  const tablesInCarticle: string[] = [];
  const tattrsInCarticle: string[] = [];

  const carticle = carticles.find(article => article.id === vid);
  carticle?.variants.forEach(({ tables, tattrs }) => {
    tablesInCarticle.push(...tables);
    tattrsInCarticle.push(...tattrs);
  });

  const tattrValues = { ...sideData.tattrValues };
  tattrsInCarticle?.forEach(id => {
    if (tattrValues[id]) delete (tattrValues[id]);
  });

  const tableValues = sideData.tableValues?.filter(({ tableId }) => !tablesInCarticle.includes(tableId));

  return {
    ...sideData,
    articleValues,
    tattrValues,
    tableValues
  };
};

export const getArticleValues = (prevValues: ISideEditData['articleValues'], vid: string, ids: string[]) => {
  const articleValues = [...prevValues];
  const index = articleValues.findIndex(item => item.id === vid);

  if (index === -1) articleValues.push({ id: vid, value: ids });
  else articleValues[index] = { id: vid, value: ids };

  return articleValues;
};
