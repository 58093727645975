import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonsContainer, Button, Modal } from '@forma/forma-ui-kit';
import VariationsBanner from './VariationsBanner';
import VariationsList from '../VariationsList';

import { ITemplateSide, ITemplateVariation } from 'interfaces/templates.interface';

import styles from './variations-select-modal.module.css';
import VariationsEditModal from '../VariationsEditModal';

interface VariationsSelectModalProps {
  open: boolean,
  onClose: () => void,
  sideId?: string,
  sides: ITemplateSide[],
  onSelect: (sideId: string, data: ITemplateVariation) => void,
  onCreate: (sideId: string, data: ITemplateVariation) => void,
  onChangeItems: (sideId: string, items: ITemplateVariation[]) => void,
}

const VariationsSelectModal: FC<VariationsSelectModalProps> = ({
  open, onClose, sides, sideId: defaultSideId, onSelect, onCreate, onChangeItems
}) => {
  const { t } = useTranslation();
  const [ sideId, setSideId ] = useState<string|null>(defaultSideId ?? null);
  const [ isAdd, setIsAdd ] = useState<boolean>(false);
  const [ variations, setVariations ] = useState<ITemplateVariation[]|null>(null);

  const side = sides.find(({ id }) => id === sideId);

  useEffect(() => {
    setSideId(defaultSideId ?? null);
  }, [defaultSideId]);

  useEffect(() => {
    setVariations(side?.carticles ?? null);
  }, [side]);

  const handleClose = () => {
    if (variations && sideId) onChangeItems(sideId, variations);
    setSideId(null);
    onClose();
  };

  const handleClickItem = (sideId: string, data: ITemplateVariation) => {
    onSelect(sideId, data);
    setSideId(null);
    onClose();
  };

  const handleCreate = (sideId: string, data: ITemplateVariation) => {
    onCreate(sideId, data);
    setSideId(null);
    onClose();
  };

  if (!sideId || !side) return (
    <Modal
      size="small"
      open={open}
      title={<span>{t('add_variations_block')}:<br />{t('select_side')}</span>}
      onClose={handleClose}
    >
      <ButtonsContainer className={styles.buttons}>
        {sides.map(({ id, name }) => (
          <Button
            viewStyle="tertiary"
            onClick={() => setSideId(id)}
            fullWidth
            key={id}
          >
            {name}
          </Button>
        ))}
      </ButtonsContainer>
    </Modal>
  );

  if (isAdd) return (
    <VariationsEditModal
      open={open}
      onClose={() => setIsAdd(false)}
      sideId={sideId}
      onSave={handleCreate}
    />
  );

  return (
    <Modal
      size="large"
      open={open}
      title={t('add_variations_block')}
      onClose={handleClose}
      buttonsClassName={styles.modalButtons}
      buttons={[
        {
          children: t('create_new_question'),
          viewStyle: 'primary',
          onClick: () => setIsAdd(true),
          closing: false,
        }
      ]}
    >
      <div className={styles.modalSelect}>
        {variations?.length ? (
          <VariationsList
            items={variations}
            onClickItem={data => handleClickItem(side.id, data)}
            onChange={setVariations}
          />
        ) : (
          <>
            <div className={styles.subtitle}>{t('add_variations_block_description')}</div>
            <div className={styles.bannerWrapper}>
              <VariationsBanner />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default VariationsSelectModal;
