import { FC } from 'react';
import { TFunction } from 'i18next';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { SearchInput, Select } from '@forma/forma-ui-kit';
import { ICounteragentType, TCounteragentSorting } from 'interfaces/counteragents.interface';
import { sortings } from 'views/contragents/model/data';

import styles from './contragents-search-header.module.css';

interface ContragentsHeaderProps {
  type: ICounteragentType,
  search: {
    onChange: (value: string) => void,
    onChangeSorting: (value: TCounteragentSorting) => void,
    sorting?: string,
  },
}

const renderSortingOption = (key: TCounteragentSorting, t: TFunction) => {
  const icon = key.startsWith('-') ? '/icons/arrow-down.svg' : '/icons/arrow-up.svg';
  return ({
    label: <span className={styles.sortOption}><ReactSVG src={icon} wrapper="span" />{t(`sortings.${key}`)}</span>,
    value: key,
  });
};

const ContragentsSearchHeader: FC<ContragentsHeaderProps> = ({ type, search }) => {
  const { t } = useTranslation();
  const sortingOptions = sortings.map(item => renderSortingOption(item, t));

  return (
    <div className={styles.root}>
      <SearchInput
        onSearch={search.onChange}
        className={styles.search}
        placeholder={type === 'entity' ? t('enter_inn_or_organization_name') : t('name_surname_lastname')}
      />
      <div className={styles.select}>
        <Select
          name="sorting"
          onChange={e => search.onChangeSorting(e.target.value as TCounteragentSorting)}
          options={sortingOptions}
          placeholder={t('sorting')}
          value={search.sorting}
        />
      </div>
    </div>
  );
};

export default ContragentsSearchHeader;
