import { FC } from 'react';
import ContragentsListJuristic from './tables/ContragentsListJuristic';
import ContragentsListIndividual from './tables/ContragentsListIndividual';
import ContragentsListPhysics from './tables/ContragentsListPhysics';

import { ICounteragentsItem, ICounteragentType, IDadataItem } from 'interfaces/counteragents.interface';

export interface ContragentsListProps {
  type: ICounteragentType
  items?: ICounteragentsItem[],
  outside?: IDadataItem[],
  onRemove: (id: string) => void,
  onAdd: (type: ICounteragentType, values: { [key: string]: string }) => void
}

const ContragentsList: FC<ContragentsListProps> = ({ type, items, outside, onRemove, onAdd }) => {
  if (type === 'entity') return (
    <ContragentsListJuristic items={items} outside={outside} onRemove={onRemove} onAdd={onAdd} />
  );
  if (type === 'individual') return (
    <ContragentsListIndividual items={items} outside={outside} onRemove={onRemove} onAdd={onAdd} />
  );
  return (
    <ContragentsListPhysics items={items} outside={outside} onRemove={onRemove} onAdd={onAdd} />
  );
};

export default ContragentsList;
