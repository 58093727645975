import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';

import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import ContragentEdit from 'views/contragents/ContragentEdit';

import { contragent_types } from 'data/mock';

import { addNotification } from 'store/notifications/notificationsSlice';
import { useCreateCounteragentMutation } from 'store/counteragents/counteragentsApi';
import {
  useGetTemplateSectionsGroupsQuery,
  useGetTemplateSectionsVariablesQuery,
  useGetUserVariablesQuery
} from 'store/schemas/schemasApi';
import { ICounteragentType } from 'interfaces/counteragents.interface';
import { selectUserPermissions } from 'store/user/userSlice';


const ContragentCreate = () => {
  const { t } = useTranslation();
  const { type } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userPermissions = useSelector(selectUserPermissions);
  const { data: variablesSections, isLoading: isSectionsLoading } = useGetTemplateSectionsGroupsQuery();
  const { data: availableVariables, isLoading: isVarsLoading } = useGetTemplateSectionsVariablesQuery();
  const { data: userVariables, isLoading: isUserVarsLoading } = useGetUserVariablesQuery();

  const [ createCounteragent, { isLoading: isCreateLoading } ] = useCreateCounteragentMutation();

  const handleSave = async (values: { [key: string]: string }) => {
    if (!type) return null;

    const result = await createCounteragent({
      type: type as ICounteragentType,
      selfowned: true,
      favorite: false,
      attrValues: values
    });
    if ('data' in result && result.data) {
      navigate(`/contragents?type=${type}`);
      dispatch(addNotification({ content: t('data_successfully_changed'), type: 'SUCCESS' }));
    }
  };

  const title = t(`${type}_person`);

  if (!type) return null;

  return (
    <AdminLayout
      title={title}
      breadcrumbs={{ items: [ { name: t('directory_contragents'), to: '/contragents', as: Link }, { name: title } ] }}
    >
      <PageTitle>{t('site_name') + ' – ' + title}</PageTitle>
      <ContragentEdit
        isLoading={isSectionsLoading || isVarsLoading || isUserVarsLoading}
        type={contragent_types[type as ICounteragentType]}
        variablesGroups={variablesSections}
        availableVariables={availableVariables}
        userVariables={userVariables}
        onSave={handleSave}
        isSaveLoading={isCreateLoading}
        canDeleteVariables={userPermissions?.includes('users')}
      />
    </AdminLayout>
  );
};

export default ContragentCreate;