import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  LoadingButton, ButtonsContainer,
  FillGroup, FillFolder, FillingItemSkelet,
  mapProductToData,
  Button,
} from '@forma/forma-ui-kit';
import SelectPropertiesModal from './SelectPropertiesModal';

import { IVariableItem } from 'interfaces/variables.interface';
import {
  IProductPropertiesItem,
  IProductsCategoriesItem,
  IProductsItem,
  IProductsItemCreate,
  IProductsItemUpdate
} from 'interfaces/products.interface';

import styles from './products-edit.module.css';

interface ProductsServicesEditProps {
  product?: IProductsItem,
  availableVariables: IVariableItem[],
  userVariables?: IProductPropertiesItem[],
  categories?: IProductsCategoriesItem[],
  isLoading?: boolean,
  // order?: string[],
  onCreate: (data: IProductsItemCreate) => void,
  onUpdate: (data: IProductsItemUpdate) => void,
  isSaveLoading?: boolean
}

const ProductsServicesEdit = ({
  product, availableVariables, userVariables, categories, isLoading, onCreate, onUpdate, isSaveLoading
}: ProductsServicesEditProps) => {
  const { t, i18n } = useTranslation();
  const [ isShowAdd, setShowAdd ] = useState<boolean>(false);
  const [ variablesKeys, setVariablesKeys ]= useState<string[]>(availableVariables.map(({ id }) => id));

  const { register, control, reset, trigger, setValue, handleSubmit, unregister, formState: { errors, isValid } }
    = useForm<{ [key: string]: string }>({
      mode: 'onChange',
    });

  useEffect(() => {
    if (!product) return;

    const availableVariablesKeys = availableVariables?.map(({ id }) => id) ?? [];
    const userVariablesKeys = userVariables?.map(({ id }) => id) ?? [];

    const settedValuesKeys = [
      ...availableVariablesKeys,
      ...userVariablesKeys?.filter(key => product.propertyValues?.[key])
    ];
    setVariablesKeys(settedValuesKeys);

    const productData = mapProductToData(product, settedValuesKeys);

    reset(productData);

    setTimeout(() => trigger(), 300);
    // eslint-disable-next-line
  }, [product, availableVariables, userVariables]);

  const tattrs = useMemo(() => {
    const items: IVariableItem[] = variablesKeys.reduce((acc: IVariableItem[], key) => {
      const item = availableVariables.find(({ id }) => id === key);
      if (item) {
        if (item.id === 'category') return [
          ...acc, {
            ...item,
            values: [ ...categories?.map(({ name }) => name) ?? [], ...item.values ].filter((item, pos, arr) => arr.indexOf(item) === pos)
          }
        ];
        return [ ...acc, item ];
      }

      if (userVariables) {
        const userItem = userVariables.find(({ id }) => id === key);
        if (userItem) return [ ...acc, { ...userItem, lang: i18n.language, required: false } ];
      }

      return acc;
    }, []);

    // if (order) {
    //   items.sort((item1, item2) => {
    //     const index1 = order.indexOf(item1.id);
    //     const index2 = order.indexOf(item2.id);
    //     if (index1 === -1 || index2 === -1) return 0;
    //     return order.indexOf(item1.id) - order.indexOf(item2.id);
    //   });
    // }

    return items;
  }, [variablesKeys, availableVariables, userVariables, categories, i18n.language]);

  const handleRemoveTattr = (id: string) => {
    setVariablesKeys(prev => prev.filter(key => key !== id));
    unregister(id);
  };

  const handleSave = (values: { [key: string]: string }) => {
    const data: IProductsItemCreate = { propertyValues: {} };
    Object.entries(values).forEach(([ key, value ]) => {
      const name = key as keyof Omit<IProductsItemCreate, 'propertyValues'>;
      if (value) {
        if (availableVariables?.find(item => item.id === key)) data[name] = value;
        else data.propertyValues![name] = value;
      }
    });

    if (product) {
      onUpdate({ id: product.id, ...data });
    } else {
      onCreate(data);
    }
  };

  return (
    <div className={styles.root}>
      <form id="product_edit_form" onSubmit={handleSubmit(handleSave)}>
        <div className={styles.container}>
          {(isLoading || !tattrs) ? (
            <FillGroup>
              <div className={styles.items}>
                {[...Array(10)].map((_key, index) => (
                  <FillingItemSkelet key={index} />
                ))}
              </div>
            </FillGroup>
          ) : (
            <FillFolder
              tattrs={tattrs}
              register={register}
              errors={errors}
              control={control}
              setValue={setValue}
              onRemove={handleRemoveTattr}
            />
          )}
          <ButtonsContainer className={styles.fieldsButtons}>
            <Button
              onClick={() => setShowAdd(!isShowAdd)}
              viewStyle="secondary"
              shadow
              disabled={!userVariables}
            >
              {t('add_data')}
            </Button>
          </ButtonsContainer>
        </div>

        <ButtonsContainer className={styles.buttons}>
          <LoadingButton
            type="submit"
            isLoading={isSaveLoading}
            disabled={!isValid}
          >
            {product ? t('save') : t('add')}
          </LoadingButton>
        </ButtonsContainer>
      </form>

      <SelectPropertiesModal
        open={isShowAdd}
        onClose={setShowAdd}
        userVariables={userVariables}
        selected={variablesKeys}
        onChangeSelected={setVariablesKeys}
      />
    </div>
  );
};

export default ProductsServicesEdit;
