
import { templatesIcons } from 'data/mock';
import { FORMA_FOLDER_ID_PACKS, FORMA_FOLDER_ID_STATIC } from 'data/constants';

import { IFolderListTreeChidlren } from 'interfaces/folders.interface';

export const getGridItemIcon = (item: IFolderListTreeChidlren, isHover?: boolean) => {
  if (item.type === 'folder') {
    if (item.external) {
      if (item.id === FORMA_FOLDER_ID_STATIC) return templatesIcons.grid.folders.static;
      if (item.id === FORMA_FOLDER_ID_PACKS) return templatesIcons.grid.folders.packs;
      return templatesIcons.grid.folders.strict;
    }

    // if (!item.children?.length) return templatesIcons.folders.empty;
    if (item.isCreated) return templatesIcons.grid.folders.created;
    if (item.isAccessAdd) return templatesIcons.grid.folders.privateAdd;
    if (item.groups?.length) {
      if (isHover) return templatesIcons.grid.folders.privateHover;
      return templatesIcons.grid.folders.private;
    }
    return templatesIcons.grid.folders.default;
  }

  if (item.external) return templatesIcons.grid.files.strict;
  if (item.isCreated) return templatesIcons.grid.files.created;
  return templatesIcons.grid.files.default;
};
