import { TCounteragentSorting } from 'interfaces/counteragents.interface';

export const sortings: TCounteragentSorting[] = [
  'name',
  '-name',
  'fullName',
  '-fullName',
  'createdAt',
  '-createdAt'
];
