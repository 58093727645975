import { FC, Fragment, lazy, Suspense, useState } from 'react';
import { FillProducts, useProductSelection } from '@forma/forma-ui-kit';
import { IProductsItem } from 'interfaces/products.interface';
import { ITemplateTable } from 'interfaces/templates.interface';

const ProductsImportModal = lazy(() => import('pages/Products/ProductsImportModal'));
const SelectProductsModal = lazy(() => import('pages/Products/SelectProductsModal'));

interface FillSideTableProps {
  data: ITemplateTable,
  products: { [key: string]: string }[],
  productsTotal: { [key: string]: string },
  customProductsIds: string[],
  hideAddSection?: boolean,
  externalValues?: { [key: string]: string },
  onChangeTable: (products: { [key: string]: string }[], total: { [key: string]: string }, customProductsIds: string[]) => void,
  showSelect?: boolean,
  selected?: boolean,
  onSelect?: (checked: boolean) => void,
}

const FillSideTable: FC<FillSideTableProps> = ({
  data, products, productsTotal, customProductsIds, hideAddSection, externalValues, onChangeTable, ...props
}) => {
  const [showingModal, setShowingModal] = useState<{ type: 'import' | 'add', index: number } | null>(null);

  const {
    addProducts, addCustomProducts, removeProducts, sortProducts, addSection,
    changeCell, applyToAll, changeTotalCell, excludeVat, setExcludeVat
  } = useProductSelection({
    products,
    productsTotal,
    customProductsIds,
    onChangeTable,
    columnIds: data.columns.map(({ id }) => id),
    totalIds: data.total.map(({ id }) => id),
    showTotalRow: data.showTotalRow,
    externalValues,
  });

  const selectedIds = products.filter(({ id }) => !!id).map(({ id }) => id);

  const handleSelectProducts = (items: IProductsItem[]) => {
    addProducts(showingModal!.index, items);
    setShowingModal(prev => ({ type: 'add', index: prev!.index + items.length }));
  };

  const handleDeselectProducts = (ids: string[]) => {
    removeProducts(ids);
    setShowingModal(prev => ({ type: 'add', index: prev!.index - ids.length }));
  };

  const handleImportProducts = async (items: { [key: string]: string }[]) => {
    addCustomProducts(showingModal!.index, items);
    setShowingModal(null);
    return true;
  };

  const handleAddManual = (index: number) => {
    addCustomProducts(index, [{}]);
  };

  return (
    <Fragment>
      <FillProducts
        data={data}
        products={products}
        productsTotal={productsTotal}
        customProductsIds={customProductsIds}
        excludeVat={excludeVat}
        onChangeExcludeVat={setExcludeVat}
        {...props}
        actions={{
          onClickAdd: (index: number) => setShowingModal({ type: 'add', index }),
          onClickImport: (index: number) => setShowingModal({ type: 'import', index }),
          onClickAddSection: !hideAddSection ? addSection : undefined,
          onClickAddManual: handleAddManual,
        }}
        changes={{
          onApplyToAll: applyToAll,
          onChangeCell: changeCell,
          onChangeTotalCell: changeTotalCell,
          onSort: sortProducts,
          onRemove: (id: string) => removeProducts([id]),
        }}
      />

      <Suspense>
        <SelectProductsModal
          open={showingModal?.type === 'add'}
          onClose={() => setShowingModal(null)}
          title={data.name}
          // columnsIds={data.columns.map(({ id }) => id)}
          selectedIds={selectedIds}
          onSelect={handleSelectProducts}
          onDeselect={handleDeselectProducts}
        />
      </Suspense>
      <Suspense>
        <ProductsImportModal
          open={showingModal?.type === 'import'}
          onClose={() => setShowingModal(null)}
          onAddProducts={handleImportProducts}
          columns={data.columns}
        />
      </Suspense>
    </Fragment>
  );
};

export default FillSideTable;
