export const SKELETON_CLASS = 'skeleton-loader';

export const TRIAL_ID = 'tariff_trial';
export const DISCOUNT_PROMOCODE = 'true40';
export const DISCOUNT_PERCENT = 40;
export const DISCOUNT_TARIFF = 'tariff_business';
export const DISCOUNT_DURATION = 180;

export const DEFAULT_PAYMENT_METHOD = 'card';

export const ORG_INN_LENGTH = 10;
export const IP_INN_LENGTH = 12;
export const ORG_OGRN_LENGTH = 13;
export const IP_OGRN_LENGTH = 15;
export const ORG_KPP_LENGTH = 9;

export const POPULAR_TARIFF = 'tariff_business';

export const FORMA_FOLDER_ID_STATIC = 'main';
export const FORMA_FOLDER_ID_PACKS = 'packs';
export const FORMA_FOLDER_ID_STRICT = 'strict';