import { useEffect, useState } from 'react';
import { contragent_types } from 'data/mock';
import { ICounteragentType } from 'interfaces/counteragents.interface';

const initialType = Object.keys(contragent_types)[0] as ICounteragentType;

const useCounteragentsSearchParams = () => {
  const [canLoad, setCanLoad] = useState(false);
  const [params, setParams] = useState<{ sort?: string, type: ICounteragentType, filter?: string }>({ type: initialType });
  const [page, setPage] = useState(1);

  useEffect(() => {
    const sorting = localStorage.getItem('contragentsSorting');
    setParams((prev) => ({ ...prev, sort: sorting || '-createdAt' }));
    setCanLoad(true);
  }, []);

  const handleChangeParams = (params: { [key: string]: string }) => {
    setParams(prev => ({ ...prev, ...params }));
    setPage(1);
    if (params.sort) localStorage.setItem('contragentsSorting', params.sort);
  };

  return {
    canLoad,
    params,
    setParams: handleChangeParams,
    page,
    setPage,
  };
};

export default useCounteragentsSearchParams;
