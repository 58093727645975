import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { Tooltip } from '@forma/forma-ui-kit';
import { templatesIcons } from 'data/mock';

import styles from './template-item.module.css';

interface TemplateItemProps {
  id: string,
  name: string,
  folderName?: string,
  onRemove?: (id: string) => void
}

const TemplateItem: FC<TemplateItemProps> = ({ id, name, folderName, onRemove }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.iconContainer}>
          <img
            className={styles.icon}
            src={templatesIcons.grid.files.default}
            alt=""
          />
        </div>
        <div className={styles.nameContainer}>
          <div className={styles.name}>{name}</div>
          {folderName &&
            <ReactSVG className={styles.arrow} src="/icons/arrow-up.svg" />
          }
          {folderName &&
            <div className={styles.folderName}>{folderName}</div>
          }
        </div>
        {onRemove &&
          <div className={styles.remove} onClick={() => onRemove(id)}>
            <Tooltip control={<img src="/icons/close.svg" className={styles.removeIcon} alt="remove" />}>
              {t('remove_from_pack')}
            </Tooltip>
          </div>
        }
      </div>
    </div>
  );
};

export default TemplateItem;
