import { FC } from 'react';
import { Grid, GridItem } from 'components/ItemsGrid';
import { Placeholder, StrictItem } from './StrictItem';

import { IStrictTemplateItem } from 'interfaces/strictTemplates.interface';

import styles from './strict-templates.module.css';

// const sizes = {
//   desktop: 5,
//   desktopM: 5,
//   desktopS: 5,
//   tablet: 4,
//   tabletS: 3,
//   phone: 2,
// };

interface StrictTemplatesProps {
  items?: IStrictTemplateItem[],
  isLoading: boolean,
  // active: string|null,
  // templates?: ITemplatesPackTemplate[],
  // templatesIsLoading: boolean,
  // search: {
  //   isLoading: boolean,
  //   results?: ITemplatesPack[],
  //   onChange: (value: string) => void,
  // },
  actions: {
    onClick: (id: string) => void,
    onDoubleClick: (id: string) => void,
  }
}

// const staticUrl = process.env.REACT_APP_STATIC_URL;

const StrictTemplates: FC<StrictTemplatesProps> = ({
  items, isLoading, actions
}) => {
  // const { t } = useTranslation();
  // const [ columns, setColumns ] = useState<number>(0);
  // const { viewport } = useContext(ThemeContext);

  // useEffect(() => {
  //   setColumns(sizes[viewport]);
  //   // eslint-disable-next-line
  // }, [viewport]);

  return (
    <div className={styles.root}>
      {/* <PacksHeader
        search={search}
        navigation={navigation}
        actions={actions}
      /> */}
      {/* {!isLoading && !items?.length && (
        <NoItemsVideo
          title={t('you_not_created_any_templates_packs')}
          src={`${staticUrl}/videos/onboarding/packs_no_items.mp4`}
        />
      )} */}
      <Grid columns={5} id="packs_grid_list" className={styles.grid}>
        {isLoading ? (
          [...Array(10).keys()].map(key => (
            <GridItem className={styles.item} key={key}>
              <Placeholder />
            </GridItem>
          ))
        ) : (
          !!items?.length && (
            items.map(({ id, name, ...rest }) => {
              return (
                <GridItem className={styles.item} key={id}>
                  <StrictItem
                    id={id}
                    name={name}
                    {...rest}
                    actions={actions}
                  />
                </GridItem>
              );
            })
          )
        )}
      </Grid>
    </div>
  );
};

export default StrictTemplates;
