const preloadImages = (urls: string[] = []) => {
  if (!urls.length) return null;
  urls.map(src => {
    const image = new Image();
    image.src = src;
    return image;
  });
};

export default preloadImages;
